import { graphql, Link } from 'gatsby';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import LandingHeader from '../components/global/landing-header/landing-header';
import Layout from '../components/global/layout/layout';

export default function ({ data }) {
  return (
    <Layout footerPosts={data.posts} title="Impressum" description="Rechtliche Informationen zur Nutzung dieser Webseite.">
      <LandingHeader
        text="Impressum"
        backgroundImage="'/images/mindfoe.svg'"
      ></LandingHeader>
      <Container>
        <Row>
          <Col>
            <p>
              Ich freue mich, dass Sie meine Website besuchen und bedanke mich für Ihr Interesse.
              Der Schutz Ihrer Privatsphäre bei der Nutzung meiner Website ist mir wichtig.
              Daher nehmen Sie bitte nachstehende Informationen sowie die <Link to="/datenschutz">Datenschutzerklärung</Link> zur Kenntnis.
            </p>
            <h2>Herausgeber</h2>
            <p>
              Calvin Sievers<br></br>
              Lynkerstraße 16<br></br>
              34466 Wolfhagen
              </p>
            <p>
              <a href="mailto:calvin@mindfoe.de">calvin@mindfoe.de</a>
            </p>
            <h2 className="mt-5">Haftung für Inhalte</h2>
            <p>
              Als Diensteanbieter bin ich gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
              Nach §§ 8 bis 10 TMG bin ich als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen
              zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            </p>
            <p>
              Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
              Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
              Bei Bekanntwerden von entsprechenden Rechtsverletzungen werde ich diese Inhalte umgehend entfernen.
            </p>
            <p>
              Mein Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte ich keinen Einfluss habe.
              Deshalb kann ich für diese fremden Inhalte keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets
              der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der 
              Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
            </p>
            <p>
              Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
              Bei Bekanntwerden von Rechtsverletzungen werde ich derartige Links umgehend entfernen.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export const pageQuery = graphql`
  {
    posts: allPrismicBlogPost(limit: 10, sort: {fields: first_publication_date, order: DESC}) {
      nodes {
        uid
        data {
          title {
            text
          }
          excerpt {
            text
          }
          title_image {
            url
          }
        }
        first_publication_date_from_now: first_publication_date(fromNow: true)
        first_publication_date_time: first_publication_date(formatString: "hh:mm")
        first_publication_date: first_publication_date(formatString: "DD.MM.YYYY")
        first_publication_date_day: first_publication_date(formatString: "dddd", locale: "de-DE")
        tags
      }
    }
  }
`
